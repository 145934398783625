import React, { useEffect, useState } from 'react';
import _, { find, filter, uniqBy } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
// import FIELDS from 'assets/data/field_labels.json';
// import SPECIFIC_FIELDS from 'assets/data/specific_field_labels.json';
// import COMPONENT_WEIGHTS from 'assets/data/component_weight.json';
import ROUND_CRITERIAS from 'assets/data/round-criterias.json';
import config from '../config';
import { useDispatch, useSelector } from 'react-redux';
import ReceiveCondition from 'components/ReceiveCondition';
import CalculationInfo from 'components/CalculationInfo';
import ScoreCalculationBar from 'components/ScoreCalculationBar';
import Iinfo from '../assets/svg/i-info.svg.jsx';
import Ir1 from '../assets/svg/r-1.svg.jsx';
import Ir2 from '../assets/svg/r-2.svg.jsx';
import Ir3 from '../assets/svg/r-3.svg.jsx';
import Ir4 from '../assets/svg/r-4.svg.jsx';
import cn from 'classnames';

const round3 = '3_2567';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ensureUrl = (link) => {
  const expression = /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gi;

  const regex = new RegExp(expression);
  const match = link.match(regex);

  if (match) {
    return match[0];
  } else {
    return `https://${link}`;
  }
};

const createObjectQuery = (programProject) => {
  let major_id = programProject.major_id;
  let project_id = programProject.project_id;
  const type = programProject.type;
  if (programProject.major_id === '0') {
    major_id = null;
  }
  if (programProject.project_id === '0') {
    project_id = null;
  }

  return `${programProject.program_id}-${major_id || 'null'}-${project_id || 'null'}-${type || 'null'}`;
};

const getReceiveQuota = (roundData) => {
  if (roundData && roundData.length > 0) {
    const roundDataWithNotJoinId = roundData.filter((data) => !data.join_id);
    const roundDataWithJoinId = roundData.filter((data) => data.join_id);
    // console.log(roundDataWithNotJoinId, roundDataWithJoinId, roundData);
    let uniqueRoundData = uniqBy(roundDataWithJoinId, 'join_id');
    uniqueRoundData = uniqueRoundData.concat(roundDataWithNotJoinId);

    return uniqueRoundData.reduce((result, data) => result + parseInt(data.receive_student_number, 10), 0);
  }
  return 0;
};

// const renderComplexScoreCondition = (scoreCriteria) => {
//   const subjects = scoreCriteria.subject_names.split(' ').map((sj) => {
//     return FIELDS[sj];
//   });

//   if (scoreCriteria.score_condition === 1) {
//     return (
//       <span>
//         คะแนนรายวิชาใดรายวิชาหนึ่งต่อไปนี้{' '}
//         {subjects.map((sj, i) => (
//           <span key={i}>
//             {sj.subjectName}
//             {i === subjects.length - 1 ? '' : ','}{' '}
//           </span>
//         ))}
//         <span> ไม่น้อยกว่า {scoreCriteria.score_minimum} คะแนน</span>
//       </span>
//     );
//   } else {
//     return (
//       <span>
//         ผลรวมของคะแนน{' '}
//         {subjects.map((sj, i) => (
//           <span key={i}>
//             {sj.subjectName}
//             {i === subjects.length - 1 ? '' : ','}{' '}
//           </span>
//         ))}
//         <span> ไม่น้อยกว่า {scoreCriteria.score_minimum} คะแนน</span>
//       </span>
//     );
//   }
// };

const renderDetail = (data, { course, courseRoundCriterias, activeInfos, setActiveInfos, lastYearPrograms }) => {
  if (!data) {
    return <div></div>;
  }

  let isRenderSpecific = false;
  const foundRoundCriteria = find(courseRoundCriterias, { round_id: data.id });
  const query = createObjectQuery(data);

  const isActive = activeInfos[query];

  _.forEach(['project_name_th', 'project_name_en', 'major_id', 'project_id', 'join_id'], (key) => {
    if (data[key] === '0') {
      delete data[key];
    }
  });
  const isOpenTcas65 = lastYearPrograms && lastYearPrograms.length === 1;

  if (data.type === round3 && !data.receive_student_number) {
    return <div></div>;
  }
  return (
    <div className={cn('info-content', { active: isActive })}>
      <h3
        className="title"
        onClick={() => {
          setActiveInfos({ ...activeInfos, [query]: !isActive });
        }}
      >
        {data.project_id ? data.project_name_th : course.program_name_th} {data.join_id && data.join_id !== '' && '(รับร่วมกัน)'}
        {data.major_name_th ? `(${data.major_name_th})` : ''}
        {data.receive_student_number && data.receive_student_number !== '0' ? (
          <span className="receive-quota">
            รับ <b>{data.receive_student_number}</b> คน
          </span>
        ) : (
          ''
        )}
        <span className="p-toggle">
          <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.34484 6.59466C1.19822 6.74128 1.1251 6.93291 1.1251 7.12491C1.1251 7.31691 1.19822 7.50853 1.34484 7.65516L8.46984 14.7802C8.76272 15.073 9.23747 15.073 9.53034 14.7802L16.6553 7.65516C16.9482 7.36228 16.9482 6.88753 16.6553 6.59466C16.3625 6.30178 15.8877 6.30178 15.5948 6.59466L9.00009 13.1894L2.40535 6.59466C2.11247 6.30178 1.63772 6.30178 1.34484 6.59466Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </h3>

      <div className="info-content-detail">
        <div className="detail basic-info">
          <div className="topic">ข้อมูลพื้นฐาน</div>
          <label>คุณสมบัติ</label>
          <ul>
            <li>
              {data.only_formal === 1 ? <i className="i-yes"></i> : <i className="i-no"></i>}
              {data.only_formal === 1 ? '' : 'ไม่'}รับผู้สมัครที่จบจาก รร. หลักสูตรแกนกลาง
            </li>
            <li>
              {data.only_international === 1 ? <i className="i-yes"></i> : <i className="i-no"></i>}
              {data.only_international === 1 ? '' : 'ไม่'}รับผู้สมัครที่จบจาก รร. หลักสูตรนานาชาติ
            </li>
            <li>
              {data.only_vocational === 1 ? <i className="i-yes"></i> : <i className="i-no"></i>}
              {data.only_vocational === 1 ? '' : 'ไม่'}รับผู้สมัครที่จบจาก รร. หลักสูตรอาชีวะ
            </li>
            <li>
              {data.only_non_formal === 1 ? <i className="i-yes"></i> : <i className="i-no"></i>}
              {data.only_non_formal === 1 ? '' : 'ไม่'}รับผู้สมัครที่จบจาก รร. หลักสูตรตามอัธยาศัย (กศน.)
            </li>
            <li>
              {data.only_ged === 1 ? <i className="i-yes"></i> : <i className="i-no"></i>}
              {data.only_ged === 1 ? '' : 'ไม่'}รับผู้สมัครที่จบหลักสูตร GED
            </li>
          </ul>
          <ReceiveCondition programProject={data}></ReceiveCondition>
        </div>

        {data.type === round3 && (
          <div className="detail score">
            <div className="topic">การคำนวณคะแนน</div>
            {data.scores && <ScoreCalculationBar scores={data.scores} />}
            <CalculationInfo programProject={data}></CalculationInfo>
          </div>
        )}

        <div className="detail desciption">
          <div className="topic">รายละเอียด</div>
          {data.description ? <div> {data.description} </div> : <div className="detail"> - </div>}
        </div>

        <div className="detail specific-requirement">
          <div className="topic">เงื่อนไขการรับ</div>
          <div>
            <table>
              <tbody>
                {data.condition && (
                  <tr key="condition">
                    <td>
                      <span dangerouslySetInnerHTML={{ __html: data.condition }}></span>
                    </td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>

            {!isRenderSpecific && !data.condition && !foundRoundCriteria && <div className="detail"> - </div>}
          </div>
        </div>

        <div className="detail link">
          <div className="topic">รายละเอียดเพิ่มเติม</div>
          {data.link ? (
            <div>
              <a href={ensureUrl(data.link)} target="_blank" rel="noopener noreferrer">
                {data.link}
              </a>
            </div>
          ) : (
            <div className="detail"> - </div>
          )}
        </div>

        {lastYearPrograms && lastYearPrograms.length > 0 && _.get(lastYearPrograms, '[0].program_name_th') === course.program_name_th && (
          <div className="detail admission">
            <details open={isOpenTcas65}>
              <summary className="topic cursor-pointer">ข้อมูลการรับสมัคร TCAS66</summary>

              {lastYearPrograms.map((oldPorgram, i) => {
                if (oldPorgram.program_name_th !== course.program_name_th) {
                  return <div key={i}></div>;
                }

                const minEstimate = _.min([oldPorgram.est_min_score_mean, oldPorgram.est_min_score_mean_sd, oldPorgram.est_min_score_regression]);
                const maxEstimate = _.max([oldPorgram.est_min_score_mean, oldPorgram.est_min_score_mean_sd, oldPorgram.est_min_score_regression]);

                return (
                  <div className="last-score" key={i}>
                    <div className="col">
                      <div className="mb-2"></div>
                      <b>หลักสูตร </b> {oldPorgram.program_name_th}
                      {oldPorgram.major_name_th !== '0' && (
                        <span>
                          <br />
                          <b>สาขา </b> {oldPorgram.major_name_th}
                        </span>
                      )}
                      {oldPorgram.project_name_th !== '0' && (
                        <span>
                          <br />
                          <b>โครงการ </b> {oldPorgram.project_name_th}
                        </span>
                      )}
                      <h5>คะแนนต่ำสุด / สูงสุด</h5>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              ประมวลผลครั้งที่ 1{' '}
                              <strong>
                                <span className="color-orange">{oldPorgram.min_score}</span> / <span>{oldPorgram.max_score}</span>
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              ประมวลผลครั้งที่ 2{' '}
                              <strong>
                                <span className="color-orange">{oldPorgram.min_score_ds}</span> / <span>{oldPorgram.max_score_ds}</span>
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="s-flex">
                        <h5>จำนวนรับ</h5>
                        <strong className="color-green">{oldPorgram.receive_student_number}</strong>
                      </div>
                      <br />
                      {minEstimate && maxEstimate && (
                        <>
                          <h5 className="color-blue">ประมาณการณ์คะแนนต่ำสุดสำหรับ TCAS67</h5>
                          <strong className="color-red">
                            {minEstimate} - {maxEstimate}
                          </strong>
                        </>
                      )}
                    </div>
                    <div className="col">
                      <h5>วิธีคำนวณคะแนน TCAS66</h5>
                      {oldPorgram.scores && <ScoreCalculationBar scores={oldPorgram.scores} />}
                      <CalculationInfo programProject={oldPorgram}></CalculationInfo>
                    </div>
                    {minEstimate && maxEstimate && (
                      <div className="full">
                        <div className="_body">
                          <strong className="color-blue">หมายเหตุ: </strong>
                          การประมาณการณ์ช่วงคะแนนต่ำสุดของแต่ละเงื่อนไขการรับเข้าหลักสูตร ใช้ข้อมูลค่าเฉลี่ยและส่วนเบี่ยงเบนมาตรฐานของรายวิชาต่างๆ
                          ของปีปัจจุบันเทียบกับปีก่อนหน้าเท่านั้น มิได้รวมถึงผลของความนิยมและพฤติกรรมของการสมัครที่เปลี่ยนแปลงไป และปัจจัยอื่นๆ เช่น
                          จำนวนรับที่เปลี่ยนแปลง ดังนั้น{' '}
                          <strong>
                            “<u>ผลที่เกิดขึ้นจริงจึงอาจจะแตกต่างจากที่ประมาณการณ์ไว้</u>{' '}
                            ขอให้ผู้สมัครคัดเลือกใช้ข้อมูลดังกล่าวร่วมกับข้อมูลปีก่อนหน้าอย่างระมัดระวัง”
                          </strong>
                        </div>
                      </div>
                    )}

                    {i !== lastYearPrograms.length - 1 && <hr />}
                  </div>
                );
              })}
            </details>
          </div>
        )}
      </div>
    </div>
  );
};

export default function ({ match }) {
  const query = useQuery();
  const [detail, setDetail] = useState(null);
  const [lastYearPrograms, setLastYearPrograms] = useState([]);
  const [isEmptyData, setIsEmptyData] = useState(false);

  const dispatch = useDispatch();
  const _courses = useSelector((state) => state.courses || []);
  const [previewCourses, setPreviewCoures] = useState();
  const loaded = useSelector((state) => state.coursesLoaded);
  const filterCondtion = { program_id: match.params.programId };
  if (query.get('major') && query.get('major') !== '0') {
    filterCondtion.major_id = query.get('major');
  }
  const courseRoundCriterias = filter(ROUND_CRITERIAS, filterCondtion);

  const [activeInfos, setActiveInfos] = useState({});

  const universities = useSelector((state) => state.universities || []);
  const courses = previewCourses || _courses;

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    const fetchLastYearProgram = async () => {
      try {
        let res;
        res = await fetch(`${config.apiBaseUrl}/ly-programs-est/${match.params.programId}.json?ts=${new Date().getTime().toString(16)}`);
        let json = await res.json();
        setLastYearPrograms(json);
      } catch (error) {
        console.error('Error while trying to fetch a program.', error);
        // setIsEmptyData(true);
      }
    };
    const fetchProgram = async () => {
      try {
        let res;
        if (query.get('preview') === 'true') {
          res = await fetch(`${config.previewBaseUrl}/program-projects/program-id/${match.params.programId}?page=0&limit=0`);
        } else {
          res = await fetch(`${config.apiBaseUrl}/rounds/${match.params.programId}.json?ts=${new Date().getTime().toString(16)}`);
        }

        let json = await res.json();
        if (query.get('major')) {
          json = json.filter((program) => program.major_id === query.get('major'));
        }
        setDetail(json);

        const _activeInfos = json.reduce((result, programProject) => {
          result[createObjectQuery(programProject)] = false;
          return result;
        }, {});
        setActiveInfos(_activeInfos);
      } catch (error) {
        console.error('Error while trying to fetch a program.', error);
        setIsEmptyData(true);
      }
    };
    fetchProgram();
    fetchLastYearProgram();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        let res;
        if (query.get('preview') === 'true') {
          res = await fetch(`${config.previewBaseUrl}/programs/program-id/${match.params.programId}?page=0&limit=0`);
        } else {
          res = await fetch(`${config.apiBaseUrl}/courses.json?ts=${new Date().getTime().toString(16)}`);
        }

        const json = await res.json();
        if (query.get('preview') === 'true') {
          setPreviewCoures(json);
        } else {
          dispatch({ type: 'UPDATE_COURSES', data: json });
        }
      } catch (error) {
        console.error('Error while trying to fetch universities.', error);
      }
    };
    const fetchUniversities = async () => {
      try {
        const res = await fetch(`${config.apiBaseUrl}/universities.json?ts=${new Date().getTime().toString(16)}`);
        const json = await res.json();
        dispatch({ type: 'UPDATE_UNIVERSITIES', data: json });
      } catch (error) {
        console.error('Error while trying to fetch universities.', error);
      }
    };
    if (!loaded) {
      fetchCourses();
      fetchUniversities();
    }
  }, [dispatch, loaded]);

  if (isEmptyData) {
    return (
      <div className="tt-sec">
        <div className="wrap s-container">
          <div className="head">
            <Link to={config.wwwBaseUrl}>
              <img src="/img/logo.svg" alt="TCAS Logo" width="160" height="60" />
            </Link>
            <p className="_h2">
              <Link to="/">
                <span className="t-back">‹</span>
                <strong>ข้อมูลหลักสูตร</strong>
              </Link>
            </p>
            <div className="sub">
              <h1 className="_h4">ไม่พบข้อมูลการเปิดรับสมัคร</h1>
            </div>
          </div>
          <div className="banner _desktop">
            <img src="/img/courses.webp" alt="ข้อมูลหลักสูตร" width="400" height="400" />
          </div>
        </div>
      </div>
    );
  }

  if (!detail) {
    return (
      <div className="tt-sec">
        <div className="wrap s-container">
          <div className="head">
            <Link to={config.wwwBaseUrl}>
              <img src="/img/logo.svg" alt="TCAS Logo" width="160" height="60" />
            </Link>
            <p className="_h2">
              <Link to="/">
                <span className="t-back">‹</span>
                <strong>ข้อมูลหลักสูตร</strong>
              </Link>
            </p>
            <div className="sub">
              <h1 className="_h4">ไม่พบข้อมูล</h1>
            </div>
          </div>
          <div className="banner _desktop">
            <img src="/img/courses.webp" alt="ข้อมูลหลักสูตร" width="400" height="400" />
          </div>
        </div>
      </div>
    );
  }

  const firstRoundData = filter(detail, { type: '1_2567' });
  const secondRoundData = filter(detail, { type: '2_2567' });
  const thirdRoundData = filter(detail, { type: '3_2567' });
  const forthRoundData = filter(detail, (ppData) => {
    return ppData.type === '41_2567' || ppData.type === '42_2567';
  });
  const findQuery = { program_id: match.params.programId };
  if (query.get('major')) {
    findQuery.major_id = query.get('major');
  }
  let course = find(courses, findQuery);

  if (courses.length === 0) {
    return <div></div>;
  }

  const mapMajorIdToText = (majorId) => {
    if (!majorId) {
      return null;
    }
    const _findQuery = { program_id: match.params.programId };
    _findQuery.major_id = majorId;

    const _course = find(courses, _findQuery);
    if (!_course) {
      return null;
    }
    return _course.major_name_th;
  };

  const getAllInfoActive = (roundData) => {
    let isActive = true;
    roundData.forEach((programProject) => {
      const query = createObjectQuery(programProject);
      if (!activeInfos[query]) {
        isActive = false;
      }
    });
    return isActive;
  };
  const toggleAllRoundInfo = (roundData) => {
    const roundActive = getAllInfoActive(roundData);
    const updateInfoActive = {};
    roundData.forEach((programProject) => {
      const query = createObjectQuery(programProject);
      updateInfoActive[query] = !roundActive;
    });

    setActiveInfos({ ...activeInfos, ...updateInfoActive });
  };

  const renderParticipatedRound = (roundData, round) => {
    const currentUniversity = find(universities, { university_id: course.university_id });
    if (!currentUniversity) {
      return <div className="not-open">-</div>;
    }

    if (roundData.length === 0) {
      if (currentUniversity[`is_accepted_round${round}`]) {
        return <div className="not-open">-</div>;
      }
      return <div className="not-open">ไม่เปิดรับสมัครในรอบนี้</div>;
    }
  };

  if (query.get('preview') === 'true') {
    _.forEach(course, (value, key) => {
      if (value === '0') delete course[key];
    });
  }

  const u_name = course.university_name_th;
  const u_id = course.university_id;
  const fct_name = course.faculty_name_th;
  const fct_id = course.faculty_id;
  const fld_name = course.field_name_th ? course.field_name_th : '';
  const fld_id = course.group_field_id + '_' + course.field_id;

  return (
    <>
      <div className="tt-sec">
        <div className="wrap s-container">
          <div className="head">
            <a href={config.wwwBaseUrl}>
              <img src="/img/logo.svg" alt="TCAS Logo" width="160" height="60" />
            </a>
            <p className="_h2">
              <Link to="/">
                <span className="t-back">‹</span>
                <strong>ข้อมูลหลักสูตร</strong>
              </Link>
            </p>
            <div className="sub">ข้อมูลการรับสมัครของ{u_name}</div>
          </div>
          <div className="banner _desktop">
            <img src="/img/courses.webp" alt="ข้อมูลหลักสูตร" width="400" height="400" />
          </div>
        </div>
      </div>

      <div className="t-box">
        <div className="t-head -light">
          <span className="h-brand">
            <img src={`https://assets.mytcas.com/i/logo/${u_id}.png`} alt={u_name} width="100" height="100" />
            <span className="name">
              <Link to={`/universities/${u_id}`}>{u_name}</Link>&nbsp;
              <small>
                <Link to={`/universities/${u_id}/faculties/${fct_id}`}>{fct_name}</Link>
                <em>›</em>
                <Link to={`/universities/${u_id}/faculties/${fct_id}/fields/${fld_id}`}>{fld_name}</Link>
              </small>
              <h1>
                {course.program_name_th}
                {Boolean(course.major_name_th) && <span> สาขาวิชา{course.major_name_th}</span>}
                <small>
                  {(course.major_name_th || '').indexOf(course.program_type_name_th) === -1 ? ` (${course.program_type_name_th})` : ''}
                  {course.campus_name_en === 'Main Campus' ? '' : ` วิทยาเขต ${course.campus_name_th}`}
                </small>
              </h1>
            </span>
          </span>
        </div>

        <nav className="tabs-nav">
          <a href="#overview" className="r0 active">
            <Iinfo />
          </a>
          <a href="#r1" className="round-1" title="รอบที่ 1">
            <span className="_desktop">รอบ</span>
            <Ir1 />
          </a>
          <a href="#r2" className="round-2" title="รอบที่ 2">
            <span className="_desktop">รอบ</span>
            <Ir2 />
          </a>
          <a href="#r3" className="round-3" title="รอบที่ 3">
            <span className="_desktop">รอบ</span>
            <Ir3 />
          </a>
          <a href="#r4" className="round-4" title="รอบที่ 4">
            <span className="_desktop">รอบ</span>
            <Ir4 />
          </a>
        </nav>

        <ul className="body t-program">
          <li id="overview" className="tabs-content round-0 active">
            <h2>รายละเอียด</h2>
            <dl>
              {course.program_name_th && (
                <>
                  <dt>ชื่อหลักสูตร</dt>
                  <dd>{course.program_name_th}</dd>
                </>
              )}
              {course.program_name_en && course.program_name_en !== '0' && (
                <>
                  <dt>ชื่อหลักสูตรภาษาอังกฤษ</dt>
                  <dd>{course.program_name_en}</dd>
                </>
              )}
              {course.program_type_name_th && course.program_type_name_th !== '0' && (
                <>
                  <dt>ประเภทหลักสูตร</dt>
                  <dd>{course.program_type_name_th}</dd>
                </>
              )}
              {course.major_name_th && course.major_name_th !== '0' && (
                <>
                  <dt>สาขาวิชา</dt>
                  <dd>{course.major_name_th}</dd>
                </>
              )}
              {course.campus_name_th && course.campus_name_th !== '0' && (
                <>
                  <dt>วิทยาเขต</dt>
                  <dd>{course.campus_name_th}</dd>
                </>
              )}
              {course.cost && course.cost !== '0' && (
                <>
                  <dt>ค่าใช้จ่าย</dt>
                  <dd>{course.cost}</dd>
                </>
              )}
              {course.graduate_rate && course.graduate_rate !== '0' && (
                <>
                  <dt>อัตราการสำเร็จการศึกษา</dt>
                  <dd>
                    {course.graduate_rate}
                    {isNaN(course.graduate_rate) ? '' : '%'}
                  </dd>
                </>
              )}
              {course.employment_rate && course.employment_rate !== '0' && (
                <>
                  <dt>อัตราการได้งานทำ</dt>
                  <dd>
                    {_.indexOf(course.employment_rate, '%') > -1 || isNaN(parseFloat(course.employment_rate))
                      ? course.employment_rate
                      : `${course.employment_rate}%`}
                  </dd>
                </>
              )}
              {course.median_salary && course.median_salary !== '0' && (
                <>
                  <dt>ค่ามัธยฐานเงินเดือน</dt>
                  <dd>{course.median_salary}</dd>
                </>
              )}
              <dt className="meta-line"></dt>
              <dd className="meta-line _desktop"></dd>
              <dt className="meta-space">รอบ 1 Portfolio</dt>
              <dd className="meta-space">
                {getReceiveQuota(firstRoundData) > 0 && (
                  <>
                    รับ <b>{getReceiveQuota(firstRoundData)}</b> คน
                  </>
                )}
                {firstRoundData.length === 0 && <span>ไม่เปิดรับสมัครในรอบนี้</span>}
              </dd>
              <dt>รอบ 2 Quota</dt>
              <dd>
                {getReceiveQuota(secondRoundData) > 0 && (
                  <>
                    รับ <b>{getReceiveQuota(secondRoundData)}</b> คน
                  </>
                )}
                {secondRoundData.length === 0 && <span>ไม่เปิดรับสมัครในรอบนี้</span>}
              </dd>
              <dt>รอบ 3 Admission</dt>
              <dd>
                {getReceiveQuota(thirdRoundData) > 0 && (
                  <>
                    รับ <b>{getReceiveQuota(thirdRoundData)}</b> คน
                  </>
                )}
                {thirdRoundData.length === 0 && <span>ไม่เปิดรับสมัครในรอบนี้</span>}
              </dd>
              <dt>รอบ 4 Direct Admission</dt>
              <dd>
                {getReceiveQuota(forthRoundData) > 0 && (
                  <>
                    รับ <b>{getReceiveQuota(forthRoundData)}</b> คน
                  </>
                )}
                {forthRoundData.length === 0 && <span>ไม่เปิดรับสมัครในรอบนี้</span>}
              </dd>
            </dl>
          </li>
          <li id="r1" className="tabs-content round-1">
            <h2 className={cn({ active: getAllInfoActive(firstRoundData) })} onClick={() => toggleAllRoundInfo(firstRoundData)}>
              <strong>1</strong>
              <span>Portfolio</span>
              {getReceiveQuota(firstRoundData) > 0 && (
                <small className="receive-quota">
                  รับ <b>{getReceiveQuota(firstRoundData)}</b> คน
                  {firstRoundData.length !== 0 && (
                    <span className="r-toggle">
                      <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.34484 6.59466C1.19822 6.74128 1.1251 6.93291 1.1251 7.12491C1.1251 7.31691 1.19822 7.50853 1.34484 7.65516L8.46984 14.7802C8.76272 15.073 9.23747 15.073 9.53034 14.7802L16.6553 7.65516C16.9482 7.36228 16.9482 6.88753 16.6553 6.59466C16.3625 6.30178 15.8877 6.30178 15.5948 6.59466L9.00009 13.1894L2.40535 6.59466C2.11247 6.30178 1.63772 6.30178 1.34484 6.59466Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  )}
                </small>
              )}
            </h2>

            {firstRoundData &&
              firstRoundData.map((data, key) => {
                return (
                  <div className="info" key={key}>
                    {renderDetail(data, { course, courseRoundCriterias, activeInfos, setActiveInfos })}
                  </div>
                );
              })}
            {firstRoundData.length === 0 && <div className="not-open">ไม่เปิดรับสมัครในรอบนี้</div>}
          </li>

          <li id="r2" className="tabs-content round-2">
            <h2 className={cn({ active: getAllInfoActive(secondRoundData) })} onClick={() => toggleAllRoundInfo(secondRoundData)}>
              <strong>2</strong>
              <span>Quota</span>
              {getReceiveQuota(secondRoundData) > 0 && (
                <small className="receive-quota">
                  รับ <b>{getReceiveQuota(secondRoundData)}</b> คน
                  {secondRoundData.length !== 0 && (
                    <span className="r-toggle">
                      <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.34484 6.59466C1.19822 6.74128 1.1251 6.93291 1.1251 7.12491C1.1251 7.31691 1.19822 7.50853 1.34484 7.65516L8.46984 14.7802C8.76272 15.073 9.23747 15.073 9.53034 14.7802L16.6553 7.65516C16.9482 7.36228 16.9482 6.88753 16.6553 6.59466C16.3625 6.30178 15.8877 6.30178 15.5948 6.59466L9.00009 13.1894L2.40535 6.59466C2.11247 6.30178 1.63772 6.30178 1.34484 6.59466Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  )}
                </small>
              )}
            </h2>

            {secondRoundData &&
              secondRoundData.map((data, key) => {
                return (
                  <div className="info" key={key}>
                    {renderDetail(data, { course, courseRoundCriterias, activeInfos, setActiveInfos })}
                  </div>
                );
              })}
            {secondRoundData.length === 0 && <div className="not-open">ไม่เปิดรับสมัครในรอบนี้</div>}
          </li>

          <li id="r3" className="tabs-content round-3">
            <h2 className={cn({ active: getAllInfoActive(thirdRoundData) })} onClick={() => toggleAllRoundInfo(thirdRoundData)}>
              <strong>3</strong>
              <span>Admission</span>
              {getReceiveQuota(thirdRoundData) > 0 && (
                <small className="receive-quota">
                  รับ <b>{getReceiveQuota(thirdRoundData)}</b> คน
                  {thirdRoundData.length !== 0 && (
                    <span className="r-toggle">
                      <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.34484 6.59466C1.19822 6.74128 1.1251 6.93291 1.1251 7.12491C1.1251 7.31691 1.19822 7.50853 1.34484 7.65516L8.46984 14.7802C8.76272 15.073 9.23747 15.073 9.53034 14.7802L16.6553 7.65516C16.9482 7.36228 16.9482 6.88753 16.6553 6.59466C16.3625 6.30178 15.8877 6.30178 15.5948 6.59466L9.00009 13.1894L2.40535 6.59466C2.11247 6.30178 1.63772 6.30178 1.34484 6.59466Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  )}
                </small>
              )}
            </h2>

            {thirdRoundData &&
              thirdRoundData.map((data, key) => {
                return (
                  <div className="info" key={key}>
                    {renderDetail(data, { course, courseRoundCriterias, activeInfos, setActiveInfos, lastYearPrograms, round: 3 })}
                  </div>
                );
              })}

            {thirdRoundData.length === 0 && <div className="not-open">ไม่เปิดรับสมัครในรอบนี้</div>}
          </li>

          <li id="r4" className="tabs-content round-4">
            <h2 className={cn({ active: getAllInfoActive(forthRoundData) })} onClick={() => toggleAllRoundInfo(forthRoundData)}>
              <strong>4</strong>
              <span>Direct Admission</span>
              {getReceiveQuota(forthRoundData) > 0 && (
                <small className="receive-quota">
                  รับ <b>{getReceiveQuota(forthRoundData)}</b> คน
                  {forthRoundData.length !== 0 && (
                    <span className="r-toggle">
                      <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M1.34484 6.59466C1.19822 6.74128 1.1251 6.93291 1.1251 7.12491C1.1251 7.31691 1.19822 7.50853 1.34484 7.65516L8.46984 14.7802C8.76272 15.073 9.23747 15.073 9.53034 14.7802L16.6553 7.65516C16.9482 7.36228 16.9482 6.88753 16.6553 6.59466C16.3625 6.30178 15.8877 6.30178 15.5948 6.59466L9.00009 13.1894L2.40535 6.59466C2.11247 6.30178 1.63772 6.30178 1.34484 6.59466Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  )}
                </small>
              )}
            </h2>

            {forthRoundData &&
              forthRoundData.map((data, key) => {
                data.major_name_th = mapMajorIdToText(data.major_id);
                return (
                  <div className="info" key={key}>
                    {renderDetail(data, { course, courseRoundCriterias, activeInfos, setActiveInfos })}
                  </div>
                );
              })}
            {renderParticipatedRound(forthRoundData, 4)}
          </li>
        </ul>
      </div>
    </>
  );
}
