import React from 'react';
import { inRange } from 'lodash';

export const wordsWithStyles = (keywords, string) => {
  const words = keywords
    .reduce((data, word) => {
      // Create an array of indexes of bold string.
      const _start = string.indexOf(word);
      if (_start === -1) return data;

      const _end = _start + word.length;
      if (data.some(({ start, end }) => inRange(_start, start, end) || inRange(_end, start, end))) {
        return data.map(({ start, end }) => {
          if (inRange(_start, start, end)) return { start, end: _end, word: string.slice(start, _end) };
          if (inRange(_end, start, end)) return { start: _start, end, word: string.slice(_start, end) };
          return { start, end, word: string.slice(start, end) };
        });
      }
      return data.concat([{ start: _start, end: _end, word: string.slice(_start, _end) }]);
    }, [])
    .sort((a, b) => a.start - b.start)
    .slice()
    .reduce((_words, { start, end }, index, array) => {
      // Create an array of words with bold style.
      const lastWord = index === array.length - 1 && end < string.length ? [{ word: string.slice(end, string.length), bold: false }] : [];

      if (!index) {
        return (
          start > 0
            ? [
                { word: string.slice(0, start), bold: false },
                { word: string.slice(start, end), bold: true },
              ]
            : [{ word: string.slice(start, end), bold: true }]
        ).concat(lastWord);
      }

      const { end: prevEnd } = array[index - 1];
      return _words.concat(
        [
          { word: string.slice(prevEnd, start), bold: false },
          { word: string.slice(start, end), bold: true },
        ].concat(lastWord),
      );
    }, []);

  if (!words.length) return [{ word: string, bold: false }];
  return words;
};

export const renderBoldString = ({ word, bold }, index) => (bold ? <strong key={index}>{word}</strong> : <span key={index}>{word}</span>);

export const formatDate = (date) => {
  const monthNamesThai = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];
  const dayNames = ['วันอาทิตย์ที่', 'วันจันทร์ที่', 'วันอังคารที่', 'วันพุธที่', 'วันพฤหัสบดีที่', 'วันศุกร์ที่', 'วันเสาร์ที่'];

  return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNamesThai[date.getMonth()]} ${date.getFullYear() + 543}`;
};
