import React from 'react';

import SUBJECTCOLORS from 'assets/subject-colors';
import { map, get } from 'lodash';

export default function ({ scores }) {
  // const [confirmResult, setConfirmResult] = useState(null);

  return (
    <div className="t-scores">
      {scores &&
        map(scores, (value, key) => {
          if (key === 'cal_type' || key === 'cal_subject_name') return null;
          return (
            <b
              key={key}
              className={key}
              style={{
                width: value + '%',
                backgroundColor: get(SUBJECTCOLORS, `${key}.color`) || '#000000',
              }}
            ></b>
          );
        })}
    </div>
  );
}
