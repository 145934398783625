import React from 'react';
export default function () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.6359 17.8428C13.4732 17.8428 14.7477 16.9159 14.7477 14.5159C14.7477 12.6621 14.0856 12.1656 13.3904 11.9173C13.9697 11.6193 14.5656 10.9904 14.5656 9.73245C14.5656 7.349 13.2414 6.62073 11.5697 6.62073C10.8698 6.62073 9.98776 6.75729 9.44264 6.84169C9.33034 6.85907 9.23234 6.87425 9.15317 6.88556V8.55728C9.73248 8.44142 10.8911 8.2428 11.5863 8.2428C12.2152 8.2428 12.7118 8.60693 12.7118 9.60004C12.7118 10.08 12.6621 11.2387 11.4373 11.2387H9.78214V12.8276H11.5863C12.6952 12.8276 12.8773 13.5062 12.8773 14.6649C12.8773 15.6083 12.4635 16.2207 11.6194 16.2207C11.007 16.2207 9.56696 16.0221 9.10352 15.9228V17.5449C9.25248 17.578 10.8911 17.8428 11.6359 17.8428Z"
        fill="#29AFB7"
      />
    </svg>
  );
}
