import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import SUBJECTS from 'assets/data/subjects.json';

export default function ({ scores }) {
  // const [confirmResult, setConfirmResult] = useState(null);
  const [subjectDictionary, setSubjectDictionary] = useState();
  useEffect(() => {
    const subjectsObject = SUBJECTS.reduce((result, subject) => {
      result[subject.key] = subject;
      return result;
    }, {});
    setSubjectDictionary(subjectsObject);
  }, []);

  if (!scores || !subjectDictionary) {
    return <></>;
  }
  if (!(scores.cal_score_sum && scores.cal_subject_name && scores.cal_type)) {
    return <></>;
  }

  const prefixText = 'ผลรวมของ';
  let displayText = '';

  // scores.cal_subject_name = 'gat|gat2|pat2|pat1|gat';

  const orCal = scores.cal_subject_name.trim().split('|');
  for (let i = 0; i < orCal.length; i++) {
    const sumCal = orCal[i].trim().split(' ');
    let phase = sumCal.length > 1 ? `${prefixText}` : '';

    sumCal.forEach((subjectKey, index) => {
      const addNewLine = sumCal.length > 1 && sumCal.length !== index + 1;

      phase += `${get(subjectDictionary, `${subjectKey}.label`)}${addNewLine ? '<br/>' : ''}`;
      if (index === sumCal.length - 1) phase += '';
    });
    displayText += phase;
    if (i + 1 < orCal.length) {
      displayText += '<br/>[หรือ]';
    }
  }
  return (
    <tr key={'complex'}>
      <td>
        <span dangerouslySetInnerHTML={{ __html: displayText }}></span>
      </td>
      <td>{scores.cal_score_sum}%</td>
    </tr>
  );
}
