import React from 'react';
export default function () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM14.4994 15.36H15.5421V13.7876H14.4994V10.3945H12.778L12.6456 13.7876H11.0235L12.4635 6.62073H10.6263L9.10352 13.8373V15.36H12.6456V17.6276H14.4994V15.36Z"
        fill="#00709A"
      />
    </svg>
  );
}
