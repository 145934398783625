import React from 'react';
import { Link } from 'react-router-dom';
import config from '../config';

export default function () {
  let current = window.location.pathname.split('/')[1];
  const base = config.wwwBaseUrl;
  if (!isNaN(current.charAt(0))) {
    current = '';
  }
  return (
    <header id="head">
      <nav className="t-nav">
        <ul>
          <li>
            <a href={`${base}`} className="home">
              <img src="/img/i/icon.svg" alt="TCAS Logo" width="20" height="24" />
              <span>หน้าแรก</span>
            </a>
          </li>
          <li>
            <a href="https://student.mytcas.com/" rel="noopener noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path
                  d="M24 4A10 10 0 1024 24 10 10 0 1024 4zM36.021 28H11.979C9.785 28 8 29.785 8 31.979V33.5c0 3.312 1.885 6.176 5.307 8.063C16.154 43.135 19.952 44 24 44c7.706 0 16-3.286 16-10.5v-1.521C40 29.785 38.215 28 36.021 28z"
                  fill="currentColor"
                ></path>
              </svg>
              <span>เข้าสู่ระบบ</span>
            </a>
          </li>
          <li className={current === '' || current === 'program' ? 'active' : ''}>
            <Link to="/">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path
                  d="M 24 6.0117188 C 22.96575 6.0117188 21.932156 6.2355937 20.972656 6.6835938 L 2.8652344 15.138672 C 2.3372344 15.384672 2 15.916047 2 16.498047 C 2 17.080047 2.3372344 17.611422 2.8652344 17.857422 L 20.972656 26.3125 C 21.949656 26.7685 22.974 26.996094 24 26.996094 C 25.026 26.996094 26.051344 26.7685 27.027344 26.3125 L 34 23.056641 L 34 35.503906 A 2.5 2.5 0 0 0 35.5 40 A 2.5 2.5 0 0 0 37 35.501953 L 37 21.835938 A 1.50015 1.50015 0 0 0 36.759766 20.765625 A 1.50015 1.50015 0 0 0 36.685547 20.664062 A 1.50015 1.50015 0 0 0 35.914062 20.138672 A 1.50015 1.50015 0 0 0 35.910156 20.136719 L 26.267578 15.666016 C 26.100483 15.481771 26 15.245683 26 14.998047 C 26 14.857047 26.03075 14.715078 26.09375 14.580078 C 26.32475 14.078078 26.921875 13.86175 27.421875 14.09375 L 37.857422 18.931641 C 38.554422 19.254641 39 19.953656 39 20.722656 L 45.134766 17.857422 C 45.154766 17.848422 45.160734 17.839078 45.177734 17.830078 C 45.465734 17.683078 45.712375 17.448813 45.859375 17.132812 C 46.209375 16.381813 45.884766 15.488672 45.134766 15.138672 L 27.027344 6.6835938 C 26.067844 6.2355937 25.03425 6.0117187 24 6.0117188 z M 10 24.396484 L 10 34.529297 C 10 36.678297 11.243969 38.636625 13.167969 39.515625 C 16.826969 41.186625 20.345875 42 23.921875 42 L 24 42 L 24.005859 42 C 26.723859 41.99 29.523422 41.503125 32.232422 40.578125 C 31.473422 39.773125 31 38.694 31 37.5 C 31 36.437 31.384 35.473125 32 34.703125 L 32 27.197266 L 27.873047 29.123047 C 26.635047 29.702047 25.332 29.996094 24 29.996094 C 22.668 29.996094 21.365953 29.702047 20.126953 29.123047 L 10 24.396484 z"
                  fill="currentColor"
                ></path>
              </svg>
              <span>
                <em className="_plus">ข้อมูล</em>หลักสูตร
              </span>
            </Link>
          </li>
          <li>
            <a href={`${base}/blueprint`}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path
                  d="M 12.5 4 C 10.015 4 8 6.015 8 8.5 L 8 39.5 C 8 41.985 10.015 44 12.5 44 L 35.5 44 C 37.985 44 40 41.985 40 39.5 L 40 20 L 28.5 20 C 26.015 20 24 17.985 24 15.5 L 24 4 L 12.5 4 z M 27 4.8789062 L 27 15.5 C 27 16.327 27.673 17 28.5 17 L 39.121094 17 L 27 4.8789062 z M 19.5 24.060547 C 20.881 24.060547 22 25.179547 22 26.560547 C 22 27.941547 20.881 29.060547 19.5 29.060547 C 18.119 29.060547 17 27.941547 17 26.560547 C 17 25.179547 18.119 24.060547 19.5 24.060547 z M 28.5 24.060547 C 29.881 24.060547 31 25.179547 31 26.560547 C 31 27.941547 29.881 29.060547 28.5 29.060547 C 27.119 29.060547 26 27.941547 26 26.560547 C 26 25.179547 27.119 24.060547 28.5 24.060547 z M 18.4375 32.324219 C 18.917998 32.292178 19.405781 32.492344 19.722656 32.902344 C 20.759656 34.235344 22.318 35 24 35 C 25.682 35 27.239391 34.234391 28.275391 32.900391 C 28.784391 32.245391 29.726859 32.128719 30.380859 32.636719 C 31.035859 33.145719 31.153484 34.086234 30.646484 34.740234 C 29.037484 36.812234 26.615 38 24 38 C 21.385 38 18.962516 36.811234 17.353516 34.740234 C 16.845516 34.086234 16.964141 33.143719 17.619141 32.636719 C 17.864391 32.445844 18.149201 32.343443 18.4375 32.324219 z"
                  fill="currentColor"
                ></path>
              </svg>

              <span>
                <em className="_desktop">โครงสร้างและ</em>
                <em className="_plus">ตัวอย่าง</em>ข้อสอบ
              </span>
            </a>
          </li>
          <li className="_mobile nav-toggle color-blue">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
              <path
                d="M24,44c11.028,0,20-8.972,20-20S35.028,4,24,4S4,12.972,4,24S12.972,44,24,44z M14.939,19.439 c0.586-0.586,1.535-0.586,2.121,0L24,26.379l6.939-6.939c0.586-0.586,1.535-0.586,2.121,0s0.586,1.535,0,2.121l-8,8 C24.768,29.854,24.384,30,24,30s-0.768-0.146-1.061-0.439l-8-8C14.354,20.975,14.354,20.025,14.939,19.439z"
                fill="currentColor"
              ></path>
            </svg>
            <span>
              เมนู<em className="_plus">เพิ่มเติม</em>
            </span>
          </li>

          <li className={current === 'universities' || current === 'university' ? 'sub active' : 'sub'}>
            <Link to="/universities">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path
                  d="M 24 4 C 23.731 4 23.462156 4.0717969 23.222656 4.2167969 L 9.6132812 12.466797 C 7.2492813 13.898797 6 15.984 6 18.5 C 6 19.329 6.672 20 7.5 20 L 40.5 20 C 41.328 20 42 19.329 42 18.5 C 42 15.984 40.750719 13.898797 38.386719 12.466797 L 24.777344 4.2167969 C 24.537844 4.0717969 24.269 4 24 4 z M 24 11 C 25.105 11 26 11.895 26 13 C 26 14.105 25.105 15 24 15 C 22.895 15 22 14.105 22 13 C 22 11.895 22.895 11 24 11 z M 11.476562 21.978516 A 1.50015 1.50015 0 0 0 10 23.5 L 10 36.5 A 1.50015 1.50015 0 0 0 11.5 38 L 36.5 38 A 1.50015 1.50015 0 0 0 38 36.5 L 38 23.5 A 1.50015 1.50015 0 1 0 35 23.5 L 35 35 L 33 35 L 33 23.5 A 1.50015 1.50015 0 1 0 30 23.5 L 30 35 L 28 35 L 28 23.5 A 1.50015 1.50015 0 1 0 25 23.5 L 25 35 L 23 35 L 23 23.5 A 1.50015 1.50015 0 1 0 20 23.5 L 20 35 L 18 35 L 18 23.5 A 1.50015 1.50015 0 1 0 15 23.5 L 15 35 L 13 35 L 13 23.5 A 1.50015 1.50015 0 0 0 11.476562 21.978516 z M 7.5 40 A 1.50015 1.50015 0 1 0 7.5 43 L 40.5 43 A 1.50015 1.50015 0 1 0 40.5 40 L 7.5 40 z"
                  fill="currentColor"
                ></path>
              </svg>
              <span>
                <em className="_plus">ข้อมูล</em>สถาบัน
              </span>
            </Link>
          </li>
          <li className={current === 'venues' ? 'sub active' : 'sub'}>
            <Link to="/venues">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path
                  d="M 24 5 C 17.383 5 12 10.383 12 17 C 12 21.805 17.940422 30.180141 21.482422 34.744141 C 22.090422 35.528141 23.009 35.978516 24 35.978516 C 24.991 35.978516 25.909578 35.528141 26.517578 34.744141 C 30.059578 30.180141 36 21.805 36 17 C 36 10.383 30.617 5 24 5 z M 24 14 C 25.657 14 27 15.343 27 17 C 27 18.657 25.657 20 24 20 C 22.343 20 21 18.657 21 17 C 21 15.343 22.343 14 24 14 z M 11.724609 30 C 10.342609 30 9.0853906 30.816125 8.5253906 32.078125 L 6.3046875 37.078125 C 5.8216875 38.165125 5.9184062 39.40925 6.5664062 40.40625 C 7.2154063 41.40425 8.3129531 42 9.5019531 42 L 38.496094 42 C 39.685094 42 40.783641 41.40425 41.431641 40.40625 C 42.080641 39.40925 42.178313 38.166125 41.695312 37.078125 L 39.474609 32.078125 C 38.913609 30.816125 37.658391 30 36.275391 30 L 33.019531 30 C 32.382531 30.965 31.691312 31.964 30.945312 33 L 36.275391 33 C 36.472391 33 36.652422 33.115875 36.732422 33.296875 L 38.953125 38.296875 C 39.049125 38.512875 38.972016 38.688437 38.916016 38.773438 C 38.860016 38.858438 38.734047 39 38.498047 39 L 9.5019531 39 C 9.2659531 39 9.1370312 38.856484 9.0820312 38.771484 C 9.0270313 38.686484 8.9499219 38.512875 9.0449219 38.296875 L 11.267578 33.296875 C 11.347578 33.116875 11.527609 33 11.724609 33 L 17.052734 33 C 16.305734 31.964 15.616469 30.965 14.980469 30 L 11.724609 30 z"
                  fill="currentColor"
                ></path>
              </svg>
              <span>
                <em className="_desktop">ข้อมูล</em>สนามสอบ
              </span>
            </Link>
          </li>
          <li className="sub">
            <a href={`${base}/news`}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path
                  d="M3,18.5v17C3,39.084,5.916,42,9.5,42h29c3.584,0,6.5-2.916,6.5-6.5v-25C45,8.019,42.981,6,40.5,6h-26	C12.019,6,10,8.019,10,10.5v24c0,0.828-0.672,1.5-1.5,1.5S7,35.328,7,34.5V14.051C4.756,14.303,3,16.19,3,18.5z M16,31.5	c0-0.828,0.671-1.5,1.5-1.5h7c0.829,0,1.5,0.672,1.5,1.5S25.329,33,24.5,33h-7C16.671,33,16,32.328,16,31.5z M16,24.5	c0-0.828,0.671-1.5,1.5-1.5h7c0.829,0,1.5,0.672,1.5,1.5S25.329,26,24.5,26h-7C16.671,26,16,25.328,16,24.5z M29,31.5	c0-0.828,0.671-1.5,1.5-1.5h7c0.829,0,1.5,0.672,1.5,1.5S38.329,33,37.5,33h-7C29.671,33,29,32.328,29,31.5z M29,24.5	c0-0.828,0.671-1.5,1.5-1.5h7c0.829,0,1.5,0.672,1.5,1.5S38.329,26,37.5,26h-7C29.671,26,29,25.328,29,24.5z M16,16.5	c0-0.828,0.671-1.5,1.5-1.5h20c0.829,0,1.5,0.672,1.5,1.5S38.329,18,37.5,18h-20C16.671,18,16,17.328,16,16.5z"
                  fill="currentColor"
                ></path>
              </svg>
              <span>
                <em className="_plus">ข่าว</em>ประกาศ
              </span>
            </a>
          </li>
          <li className="sub">
            <a href={`${base}/docs`}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path
                  d="M 13.5 4 C 10.467 4 8 6.467 8 9.5 L 8 36 L 8 37.5 L 8 38.5 C 8 41.519774 10.480226 44 13.5 44 L 39.5 44 A 1.50015 1.50015 0 1 0 39.5 41 L 13.5 41 C 12.273237 41 11.277238 40.152347 11.050781 39 L 39.5 39 C 40.329 39 41 38.329 41 37.5 L 41 9.5 C 41 6.467 38.533 4 35.5 4 L 35 4 L 35 20.5 C 35 21.029 34.720625 21.519062 34.265625 21.789062 C 34.029625 21.929063 33.765 22 33.5 22 C 33.253 22 33.00525 21.938406 32.78125 21.816406 L 28 19.207031 L 23.21875 21.816406 C 22.75475 22.070406 22.191375 22.060063 21.734375 21.789062 C 21.279375 21.520063 21 21.029 21 20.5 L 21 4 L 13.5 4 z M 24 4 L 24 17.972656 L 27.28125 16.183594 C 27.50425 16.061594 27.753 16 28 16 C 28.247 16 28.49575 16.061594 28.71875 16.183594 L 32 17.972656 L 32 4 L 24 4 z"
                  fill="currentColor"
                ></path>
              </svg>
              <span>
                คู่มือ<em className="_plus">การใช้งาน</em>
              </span>
            </a>
          </li>
          <li>
            <a href={`${base}/stat`}>
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
                <path
                  d="M 12 6 C 8.686 6 6 8.686 6 12 L 6 16 L 16 16 L 16 6 L 12 6 z M 19 6 L 19 16 L 42 16 L 42 12 C 42 8.686 39.314 6 36 6 L 19 6 z M 6 19 L 6 36 C 6 39.314 8.686 42 12 42 L 16 42 L 16 19 L 6 19 z M 39 19 L 39 36.5 C 39 37.898226 37.898226 39 36.5 39 L 19 39 L 19 42 L 36.5 42 C 39.519774 42 42 39.519774 42 36.5 L 42 19 L 39 19 z M 35.470703 21.986328 A 1.50015 1.50015 0 0 0 34.439453 22.439453 L 28.5 28.378906 L 26.560547 26.439453 A 1.50015 1.50015 0 0 0 24.439453 26.439453 L 19.439453 31.439453 A 1.50015 1.50015 0 1 0 21.560547 33.560547 L 25.5 29.621094 L 27.439453 31.560547 A 1.50015 1.50015 0 0 0 29.560547 31.560547 L 36.560547 24.560547 A 1.50015 1.50015 0 0 0 35.470703 21.986328 z"
                  fill="currentColor"
                ></path>
              </svg>
              <span>
                สถิติ<em className="_plus">การสอบ</em>
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}
