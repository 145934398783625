import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import config from '../config';

// https://tcas65.s3.ap-southeast-1.amazonaws.com/mytcas/universities.json
// https://tcas65.s3.ap-southeast-1.amazonaws.com/mytcas/courses.json
// https://tcas65.s3.ap-southeast-1.amazonaws.com/mytcas/rounds/[program_id].json

export default function ({ match }) {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses || []);
  const loaded = useSelector((state) => state.coursesLoaded);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await fetch(`${config.apiBaseUrl}/courses.json?ts=${new Date().getTime().toString(16)}`);
        const json = await res.json();
        dispatch({ type: 'UPDATE_COURSES', data: json });
      } catch (error) {
        console.error('Error while trying to fetch courses.', error);
      }
    };
    if (!loaded) {
      fetchCourses();
    }
  }, [dispatch, loaded]);

  const faculties = uniqBy(
    courses.filter(({ university_id }) => university_id === match.params.universityId),
    ({ faculty_id }) => faculty_id,
  );
  const u_name = get(faculties, '0.university_name_th');
  const u_id = match.params.universityId;

  return (
    <>
      <div className="tt-sec">
        <div className="wrap s-container">
          <div className="head">
            <a href={config.wwwBaseUrl}>
              <img src="/img/logo.svg" alt="TCAS Logo" width="160" height="60" />
            </a>
            <p className="_h2">
              <Link to="/universities">
                <span className="t-back">‹</span>
                <strong>ข้อมูลสถาบัน</strong>
              </Link>
            </p>
            <div className="sub">ข้อมูลการรับสมัครของ{u_name ? u_name : 'สถาบัน'}</div>
          </div>
          <div className="banner _desktop">
            <img src="/img/university.webp" alt="ข้อมูลสถาบัน" width="400" height="400" />
          </div>
        </div>
      </div>

      <div className="t-box">
        <h1 className="t-head -light">
          <span className="h-brand">
            <img src={`https://assets.mytcas.com/i/logo/${u_id}.png`} alt={u_name} width="100" height="100" />
            <span>
              <Link to={`/universities/${u_id}`}>{u_name}</Link>&nbsp;
            </span>
          </span>
        </h1>
        <h2 className="sub -dark">ชื่อคณะ</h2>

        <ul className="u-list">
          {faculties.map(({ faculty_id, faculty_name_th }, index) => (
            <li key={faculty_id}>
              <Link to={`/universities/${match.params.universityId}/faculties/${faculty_id}`}>
                {index + 1}. {faculty_name_th}
              </Link>
            </li>
          ))}
          {faculties.length === 0 && <li>ไม่พบข้อมูล</li>}
        </ul>
      </div>
    </>
  );
}
