import React from 'react';
export default function () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 0C5.37235 0 0 5.37235 0 12C0 18.6277 5.37235 24 12 24C18.6277 24 24 18.6277 24 12C24 5.37235 18.6277 0 12 0ZM12 4.69565C12.8645 4.69565 13.5652 5.39635 13.5652 6.26087C13.5652 7.12539 12.8645 7.82609 12 7.82609C11.1355 7.82609 10.4348 7.12539 10.4348 6.26087C10.4348 5.39635 11.1355 4.69565 12 4.69565ZM14.087 18.7826H13.0435H10.9565H9.91304V17.7391H10.9565V10.9565H9.91304V9.91304H10.9565H13.0435V10.9565V17.7391H14.087V18.7826Z"
        fill="currentColor"
      />
    </svg>
  );
}
