import React from 'react';
import FIELDS from 'assets/data/field_labels.json';
import SUBJECTS from 'assets/data/subjects.json';
import { get, find, map } from 'lodash';
import moment from 'moment';

export default function ({ programProject }) {
  const complexCondition = get(programProject, 'score_conditions.score_condition');
  let complexSubject = []; //get(programProject, 'score_conditions.subject_names').split(' ');
  if (complexCondition) {
    complexSubject = get(programProject, 'score_conditions.subject_names').split(' ');
    complexSubject = complexSubject.map((minSubjectKey) => {
      let subjectKey = minSubjectKey.replace('min_', '');
      const isTscore = minSubjectKey.indexOf('_tscore') > -1;
      subjectKey = subjectKey.replace('_tscore', '');

      const subject = find(SUBJECTS, { key: subjectKey });
      if (subject) return `${subject.label}${isTscore ? ' (T-Score)' : ''}`;
      return '';
    });
  }

  const formatAgeDate = (date) => {
    return moment(date).add(543, 'years').format('DD/MM/YYYY');
  };

  return (
    <>
      {map(FIELDS, (FIELD, key) => {
        let value = programProject[key];

        if (value && value !== '0.0' && value !== '0') {
          if (['min_age_date', 'max_age_date'].indexOf(key) > -1) value = formatAgeDate(value);
          return (
            <div key={key}>
              <i className="i-yes"></i> {FIELD.label} <b>{value}</b>
            </div>
          );
        }
      })}

      {map(SUBJECTS, (subject) => {
        const key = `min_${subject.key}`;

        const isFoundInField = !!FIELDS[key];
        const score = get(programProject, `score_conditions.${key}`);

        if (score && score !== '0.0' && score !== '0' && !isFoundInField) {
          return (
            <div key={key}>
              <i className="i-yes"></i>คะแนนขั้นต่ำวิชา {subject.label} <b>{score}</b>
            </div>
          );
        }
      })}

      {map(SUBJECTS, (subject) => {
        const key = `min_${subject.key}`;
        const tscoreKey = `min_${subject.key}_tscore`;

        const isFoundInField = !!FIELDS[key];

        const tscore = get(programProject, `score_conditions.${tscoreKey}`);

        if (tscore && tscore !== '0.0' && tscore !== '0' && !isFoundInField) {
          return (
            <div key={tscoreKey}>
              <i className="i-yes"></i>คะแนน T-Score ขั้นต่ำวิชา {subject.label} <b>{tscore}</b>
            </div>
          );
        }
      })}

      {map(SUBJECTS, (subject) => {
        const key = `min_${subject.key}`;
        const pscoreKey = `min_${subject.key}_pr`;
        const isFoundInField = !!FIELDS[key];

        const pscore = get(programProject, `score_conditions.${pscoreKey}`);

        if (pscore && pscore !== '0.0' && pscore !== '0' && !isFoundInField) {
          return (
            <div key={pscoreKey}>
              <i className="i-yes"></i>คะแนน Percentile ขั้นต่ำวิชา {subject.label} <b>{pscore}</b>
            </div>
          );
        }
      })}

      {complexCondition === 1 && (
        <div key={'score-condition'}>
          <i className="i-yes"></i> {`คะแนนขั้นต่ำของวิชาใดวิชาหนึ่งระหว่าง${complexSubject.join(', ')}`}{' '}
          <b>{get(programProject, 'score_conditions.score_minimum')}</b>
        </div>
      )}
      {complexCondition === 2 && (
        <div key={'score-condition'}>
          <i className="i-yes"></i> {`คะแนนขั้นต่ำของผลรวม${complexSubject.join(', ')}`}{' '}
          <b>{get(programProject, 'score_conditions.score_minimum')}</b>
        </div>
      )}
    </>
  );
}
