import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import App from 'containers/App';

const INITIAL_STATE = {
  courses: [],
  universities: [],
  venues: [],
  venueDetails: [],
  coursesLoaded: false,
  universitiesLoaded: false,
};
const reducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case 'UPDATE_COURSES':
      return { ...state, coursesLoaded: true, courses: data };
    case 'UPDATE_UNIVERSITIES':
      return { ...state, universitiesLoaded: true, universities: data };
    case 'UPDATE_VENUES':
      return { ...state, venuesLoaded: true, venues: data };
    case 'UPDATE_VENUE_DETAILS':
      return { ...state, venueDetailLoaded: true, venueDetails: data };
    default:
      return state;
  }
};

const middleware = [];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route path="/" component={App} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

var firebaseConfig = {
  apiKey: 'AIzaSyB5-_VFBOuxfp_AaF6r345WwDSC04PqKpM',
  authDomain: 'tcas-web.firebaseapp.com',
  projectId: 'tcas-web',
  storageBucket: 'tcas-web.appspot.com',
  messagingSenderId: '239493655090',
  appId: '1:239493655090:web:f3c047d97acb64a6db6a2b',
};
const app = initializeApp(firebaseConfig);
window.firebase = app;
window.analytics = getAnalytics(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
