import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import config from '../config';

export default function ({ match }) {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses || []);
  const loaded = useSelector((state) => state.coursesLoaded);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await fetch(`${config.apiBaseUrl}/courses.json?ts=${new Date().getTime().toString(16)}`);
        const json = await res.json();
        dispatch({ type: 'UPDATE_COURSES', data: json });
      } catch (error) {
        console.error('Error while trying to fetch courses.', error);
      }
    };
    if (!loaded) {
      fetchCourses();
    }
  }, [dispatch, loaded]);

  let matchGroupId, matchFieldId;
  const matchGroupFieldId = match.params.fieldId.split('_');
  if (matchGroupFieldId.length === 2) {
    matchGroupId = matchGroupFieldId[0] === '' ? null : matchGroupFieldId[0];
    matchFieldId = matchGroupFieldId[1];
  } else {
    matchFieldId = matchGroupFieldId[0];
  }

  const programs = uniqBy(
    courses.filter(
      ({ university_id, faculty_id, field_id, group_field_id }) =>
        university_id === match.params.universityId &&
        faculty_id === match.params.facultyId &&
        field_id === matchFieldId &&
        (!matchGroupId || matchGroupId === group_field_id),
    ),
    ({ program_id, major_id }) => `${program_id}_${major_id}`,
  );

  const u_name = get(programs, '0.university_name_th');
  const u_id = match.params.universityId;
  const fct_name = get(programs, '0.faculty_name_th');
  const fct_id = match.params.facultyId;
  const fld_name = get(programs, '0.field_name_th');
  const fld_id = matchGroupId + '_' + matchFieldId;

  console.log(programs);

  return (
    <>
      <div className="tt-sec">
        <div className="wrap s-container">
          <div className="head">
            <a href={config.wwwBaseUrl}>
              <img src="/img/logo.svg" alt="TCAS Logo" width="160" height="60" />
            </a>
            <p className="_h2">
              <Link to="/universities">
                <span className="t-back">‹</span>
                <strong>ข้อมูลสถาบัน</strong>
              </Link>
            </p>
            <div className="sub">ข้อมูลการรับสมัครของ{u_name}</div>
          </div>
          <div className="banner _desktop">
            <img src="/img/university.webp" alt="ข้อมูลสถาบัน" width="400" height="400" />
          </div>
        </div>
      </div>
      <div className="t-box">
        <h1 className="t-head -light">
          <span className="h-brand">
            <img src={`https://assets.mytcas.com/i/logo/${u_id}.png`} alt={u_name} width="100" height="100" />
            <span>
              <Link to={`/universities/${u_id}`}>{u_name}</Link>&nbsp;
              <small>
                <Link to={`/universities/${u_id}/faculties/${fct_id}`}>{fct_name}</Link>
                <em>›</em>
                <Link to={`/universities/${u_id}/faculties/${fct_id}/fields/${fld_id}`}>{fld_name}</Link>
              </small>
            </span>
          </span>
        </h1>
        <h2 className="sub -dark">ชื่อหลักสูตร</h2>
        <ul className="u-list">
          {programs.map(({ program_id, program_name_th, program_type_name_th, major_name_th, campus_name_th, campus_name_en, major_id }, index) => {
            return (
              <li key={program_id}>
                <Link to={`/programs/${program_id}?${major_id ? `major=${major_id}` : ''}`}>
                  <span>{index + 1}. </span>
                  <span className="program">{program_name_th}</span>
                  {Boolean(major_name_th) && <span className="major"> สาขาวิชา{major_name_th}</span>}
                  <span className="program-type">
                    {(major_name_th || '').indexOf(program_type_name_th) === -1 ? ` (${program_type_name_th})` : ''}
                  </span>
                  <span className="campus">{campus_name_en === 'Main Campus' ? '' : ` วิทยาเขต ${campus_name_th}`}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
