import React from 'react';
export default function () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.10352 17.7435H14.7477V16.1214H11.3711L13.4732 13.4069C14.5159 12.0662 14.8966 11.2221 14.8966 9.64969C14.8966 7.64693 13.7049 6.62073 11.7849 6.62073C10.9904 6.62073 9.58352 6.90211 9.3849 6.95176V8.55728C9.96421 8.44142 11.1725 8.2428 11.7849 8.2428C12.5297 8.2428 13.0428 8.789 13.0428 9.51728C13.0428 11.0069 12.629 11.6525 11.8842 12.5959L9.10352 16.1214V17.7435Z"
        fill="#F26B55"
      />
    </svg>
  );
}
