import React from 'react';
export default function () {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.26903 17.6276H14.6483V16.0386H13.0594V6.62067H11.2718L9.10352 7.4317V9.18618L11.189 8.4248V16.0386H9.26903V17.6276Z"
        fill="#FFB600"
      />
    </svg>
  );
}
