import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import config from '../config';

export default function () {
  const dispatch = useDispatch();
  const universities = useSelector((state) => state.universities || []);
  const loaded = useSelector((state) => state.universitiesLoaded);

  useEffect(() => {
    const fetchUniversities = async () => {
      try {
        const res = await fetch(`${config.apiBaseUrl}/universities.json?ts=${new Date().getTime().toString(16)}`);
        const json = await res.json();
        dispatch({ type: 'UPDATE_UNIVERSITIES', data: json });
      } catch (error) {
        console.error('Error while trying to fetch universities.', error);
      }
    };
    if (!loaded) {
      fetchUniversities();
    }
  }, [dispatch, loaded]);

  let _mtrUniversities = universities.filter(({ university_name }) => university_name.indexOf('มหาวิทยาลัยเทคโนโลยีราชมงคล') > -1);
  let _rcpUniversities = universities.filter(({ university_name }) => university_name.indexOf('มหาวิทยาลัยราชภัฏ') > -1);
  let _otherUniversities = universities
    .filter(
      ({ university_name }) => university_name.indexOf('มหาวิทยาลัยเทคโนโลยีราชมงคล') === -1 && university_name.indexOf('มหาวิทยาลัยราชภัฏ') === -1,
    )
    .sort((a, b) => a.university_id - b.university_id)
    .slice();
  // _mtrUniversities = sortBy(_mtrUniversities, 'university_id');
  // _rcpUniversities = sortBy(_rcpUniversities, 'university_id');
  // _otherUniversities = sortBy(_otherUniversities, 'university_id');

  let _universities = _mtrUniversities.concat(_rcpUniversities).concat(_otherUniversities);
  _universities = _universities.map((university) => {
    const vowels = ['เ', 'แ', 'ไ', 'า', 'โ', 'ใ', 'ิ', 'ี', 'ึ', 'ื'];
    university.nameIndexing = university.university_name;
    vowels.forEach((vowel) => {
      university.nameIndexing = university.nameIndexing.replaceAll(vowel, '');
    });

    return university;
  });

  _universities = sortBy(_universities, ['university_type', 'nameIndexing']);

  return (
    <>
      <div className="tt-sec">
        <div className="wrap s-container">
          <div className="head">
            <a href={config.wwwBaseUrl}>
              <img src="/img/logo.svg" alt="TCAS Logo" width="160" height="60" />
            </a>
            <h1>ข้อมูลสถาบัน</h1>
            <div className="sub">รายชื่อมหาวิทยาลัยและสถาบันต่าง ๆ ที่เปิดรับสมัคร</div>
          </div>
          <div className="banner _desktop">
            <img src="/img/university.webp" alt="ข้อมูลสถาบัน" width="400" height="400" />
          </div>
        </div>
      </div>

      <div className="t-box">
        <h2 className="t-head">
          <span>ข้อมูลการรับสมัครของมหาวิทยาลัย ปีการศึกษา 2567</span>
          <small>
            <span className="_mobile">และ</span>เอกสารเผยแพร่ในแต่ละรอบ
          </small>
        </h2>
        <ul className="u-list unv-list">
          {_universities.map(({ university_id, university_name, file_path_1, file_path_2, file_path_3, file_path_4, file_path_handicap }) => (
            <li key={university_id}>
              <Link to={`/universities/${university_id}`} className="brand">
                <img src={`https://assets.mytcas.com/i/logo/${university_id}.png`} alt={`${university_name} Logo`} width="60" height="60" />
                {university_name}
              </Link>

              <ul className="files">
                <li>
                  {Boolean(file_path_1) && (
                    <a
                      className="f1"
                      href={file_path_1}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        window &&
                          window.analytics.logEvent('download_qualification', {
                            file: 'ดาวน์โหลดไฟล์ PDF สำหรับรูปแบบที่ 1',
                            university: university_name,
                            url: file_path_1,
                          });
                      }}
                    >
                      <img src="/img/i/i-pdf-1.svg" alt="ดาวน์โหลดไฟล์ PDF สำหรับรูปแบบที่ 1" />
                    </a>
                  )}
                </li>
                <li>
                  {Boolean(file_path_2) && (
                    <a
                      className="f2"
                      href={file_path_2}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        window &&
                          window.analytics.logEvent('download_qualification', {
                            file: 'ดาวน์โหลดไฟล์ PDF สำหรับรูปแบบที่ 2',
                            university: university_name,
                            url: file_path_2,
                          });
                      }}
                    >
                      <img src="/img/i/i-pdf-2.svg" alt="ดาวน์โหลดไฟล์ PDF สำหรับรูปแบบที่ 2" />
                    </a>
                  )}
                </li>
                <li>
                  {Boolean(file_path_3) && (
                    <a
                      className="f3"
                      href={file_path_3}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        window &&
                          window.analytics.logEvent('download_qualification', {
                            file: 'ดาวน์โหลดไฟล์ PDF สำหรับรูปแบบที่ 3',
                            university: university_name,
                            url: file_path_3,
                          });
                      }}
                    >
                      <img src="/img/i/i-pdf-3.svg" alt="ดาวน์โหลดไฟล์ PDF สำหรับรูปแบบที่ 3" />
                    </a>
                  )}
                </li>
                <li>
                  {Boolean(false) && (
                    <a
                      className="f4"
                      href={file_path_4}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        window &&
                          window.analytics.logEvent('download_qualification', {
                            file: 'ดาวน์โหลดไฟล์ PDF สำหรับรูปแบบที่ 4',
                            university: university_name,
                            url: file_path_4,
                          });
                      }}
                    >
                      <img src="/img/i/i-pdf-4.svg" alt="ดาวน์โหลดไฟล์ PDF สำหรับรูปแบบที่ 4" />
                    </a>
                  )}
                </li>
                <li>
                  {Boolean(file_path_handicap) && (
                    <a
                      className="fd"
                      href={file_path_handicap}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        window &&
                          window.analytics.logEvent('download_qualification', {
                            file: 'ดาวน์โหลดไฟล์ PDF สำหรับผู้พิการ',
                            university: university_name,
                            url: file_path_handicap,
                          });
                      }}
                    >
                      <img src="/img/i/i-disability.svg" alt="ดาวน์โหลดไฟล์ PDF สำหรับผู้พิการ" />
                    </a>
                  )}
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
