import React, { useEffect, useState } from 'react';
import { get, map } from 'lodash';
import SUBJECTS from 'assets/data/subjects.json';
import ComplexSubjectInfo from './ComplexSubjectInfo';

export default function ({ scores, programProject }) {
  // const [confirmResult, setConfirmResult] = useState(null);
  const [subjectDictionary, setSubjectDictionary] = useState();
  useEffect(() => {
    const subjectsObject = SUBJECTS.reduce((result, subject) => {
      result[subject.key] = subject;
      return result;
    }, {});
    setSubjectDictionary(subjectsObject);
  }, []);

  if (!subjectDictionary) {
    return <span></span>;
  }

  return (
    <table>
      <tbody>
        {programProject.scores &&
          map(programProject.scores, (value, key) => {
            if (key === 'cal_score_sum' || key === 'cal_subject_name' || key === 'cal_type') {
              return <></>;
            }
            if (value === 0) {
              return <></>;
            }
            return (
              <tr key={key}>
                <td>{get(subjectDictionary, `${key}.label`)}</td>
                <td>{value}%</td>
              </tr>
            );
          })}
        {programProject.scores && <ComplexSubjectInfo scores={programProject.scores} />}
      </tbody>
    </table>
  );
}
