import React from 'react';
export default function () {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33325 23.2333C9.33325 24.3911 10.2586 25.3333 11.3958 25.3333H16.6666V19.9667C16.6666 18.8089 17.592 17.8667 18.7291 17.8667H23.9999V8.76666C23.9999 7.60886 23.0745 6.66666 21.9374 6.66666H11.3958C10.2586 6.66666 9.33325 7.60886 9.33325 8.76666V23.2333ZM18.0416 19.9667V24.9232L23.5971 19.2667H18.7291C18.35 19.2667 18.0416 19.5807 18.0416 19.9667ZM15.29 15.4121H16.8184C17.6159 15.4121 18.2295 15.2363 18.6592 14.8848C19.0889 14.5332 19.3037 13.9326 19.3037 13.083C19.3037 12.305 19.0889 11.7305 18.6592 11.3594C18.2295 10.9883 17.6533 10.8027 16.9307 10.8027H13.7959V18H15.29V15.4121ZM17.8145 13.0928C17.8145 13.4671 17.7168 13.7406 17.5215 13.9131C17.3229 14.0856 17.0462 14.1719 16.6914 14.1719H15.29V12.0527H16.6914C17.0462 12.0527 17.3213 12.1325 17.5166 12.292C17.7152 12.4515 17.8145 12.7184 17.8145 13.0928Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
